<template>
  <div class="wrapper">
    <div class="building-experience">
    <h1 class="">Reflecting On My Experiences Building This Platform</h1>
    <p>By Nelly Nakhero</p>

    <div class="story">
      <p>In this blog post, I will be taking you through a quick overview of the building of this platform, <b>Hey Guys</b>. 
        Starting with the ideation and the stumbing into the problem, to problem research, to the making of the UI designs, 
        chosing the frameworks to use, designing the APIs and writing the code for backend services, 
        to writing the frontend.</p>
        <p>The making of this platform was the challenge I was ready for, I have been a fullstack developer for 
          almost 4 years now, and thus confident (<i>just a little bit</i>),  that I can come up with a working solution. 
          Over the years I have progressed from learninng to write frontend code, falling in love with web development, to 
           understanding importance of backend, falling even more in love with logic and backend, gaining interest in DevOps, 
          understanding DevOps and how the whole software development life cycle conjures.</p>
          <p>In all these I have always carried  curiosity along and whichever paths it has led me , I
            always give my best and made sure to learn not just from mistakes but also from success.</p>
            <br/>
            <br/>

      <div class="ideation">
        <h4>👣 First steps: Ideation</h4>
        <p>
          <span>As a software developer you spot a problem and you try and come up with a solution. The "Hey Guys" platform is designed to help families or friends collaborate on joint projects by creating squads. This platform allows members to contribute financially, assign contributions and targets, set up budgets and expenditure limits, and track progress within a secure and user-friendly interface. </span>
        </p>
        <blockquote>
          <p class="personal-story">
            <span>&#8220;</span>A couple of my friends and I wanted to start a chicken business. 
            We had collectively agreed that each of us will contribute a certain amount as the starting capital. 
            Therefore, we needed a central place where we would channel our contributions to. 
            We  wanted something quick and easy and joint bank account seemed like an obvious option. 
            Oooh how wrong we were, the proccesses to accrue the joint account was anything but quick and easy, 
            considering each of my friends were in different regions of the country we had to aorganize and have a 
            few friends meet up to go open joint account account because one of the requirement for the bank was 
            to have altleast two representatives in person. 
            Twice we had to go back to the bank just to have joint account, and during all these times we had to 
            wait on long queues and even when we got served, there was always issues with the banking system. 
            After about two days of waiting in the bank for over 4 hours we gave up trying because our schedules 
            were getting inconvinienced and the process was expensive on both our time and finances(from travelling to and fro the bank). <span>&#8221;</span> 
          </p>
        </blockquote>
        <p> 
          <span>
            Many families and friends, like my "<i>chicken business friends</i>", face difficulties in managing joint projects that involve shared financial contributions and coordination. "Hey Guys" addresses this by providing an organized, transparent, and collaborative platform to handle such projects.
          </span>
        </p>
      </div>

      <div class="problem-statement">
        <h4>🤦🏾‍♀️ Problem Statement</h4>
        <p>Many families and friends face difficulties in managing joint projects that involve shared financial contributions and coordination. "Hey Guys" addresses this by providing an organized, transparent, and collaborative platform to handle such projects.</p>
      </div>
    </div>

    <div class="solution-addressed">
      <h4>🎯 Solution Statement</h4>
      <p>We're building a platform to help manage small projects collaboration and funds by making it eisier to set up a project's squad, invite members to a project, manage contributions while also staying transparent with funds coming in and out of the project's wallet.</p>
    </div>
    
    <div class="key-features">
      <h4>🗂️ Key Features</h4>
      <ul>
        <li>Create Squads and Add Members: A user can create a squad and invite members.</li>
        <li>Assign Financial Contributions: Squad creators can assign how much each member must contribute.</li>
        <li>Notifications: Members are notified of due dates and when payments are made.</li>
        <li>Wallet Integration: Secure wallet for making and tracking payments.</li>
        <li>Task Assignment: Ability to assign tasks to squad members.</li>
        <li>Progress Tracking: Track contributions and task completion.</li>
      </ul>
    </div>

    <div class="tech-stack">
      <h4>🧮 Tech Stack</h4>
      <ul>
        <li>Backend: Java (Spring Framework)</li>
        <li>Frontend: Vue.js</li>
        <li>Notifications: Email/Dashboard notifications</li>
        <li>Payment Gateway: MPESA</li>
        <li>Wallet: Inhouse developed</li>
      </ul>
    </div>

    <div class="additional-features">
      <h4>🪺 Additional Features</h4>
      <ul>
        <li>Progress Tracking: Allows users to track the status of payments and tasks within each squad.</li>
        <li>Chat/Communication: Users are able to communicate within the squad to discuss their project in real time.</li>
        <li>Recurring Contributions: If the squad is working on a long-term project, the platform allows for monthly/recurring payments.</li>
        <li>Voting Feature: Enable members to vote on decisions related to the project or squad.</li>
        <li>Task Assignment: Users can assign tasks to squad members to track non-financial responsibilities besides monetary contributions.</li>
        <li>Project Completion Metrics: Includes progress bar or completion metrics showing how close the squad is to completing the project (financially or task-wise).</li>
        <li>Leader/Manager Role: Option for a leader or manager within each squad, who can approve or manage contributions.</li>
        <li>Security & Payment Integration: We have intergrated secure payments system, (Stripe and PayPal are in pipeline), with two-factor authentication for additional security.</li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@import url("./experiencepost.css");
</style>